.invoiceBodyContainer{
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.g-invoiceHeader-table{
    width: 32%;
}

.data-col{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.data{
    margin: 0.1rem;
}

.data p{
    font-weight: lighter;
}

.generatedInvoiceContainer{
    width: 30cm;
    height: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

/* .g-invoiceHeader-col{
    border: 1px solid #09bdc5;
} */

.salaryNumber-container{
    width: 80%;
    display: flex;
    justify-content: end;
}

.totalAmount-container{
    width: 100%;
    display: flex;
    justify-content: end;
}
.totalAmount-container td {
    padding: 0px 30px;
}
.totalAmount-container th {
    padding-left: 20px;
    padding-right: 20px;
}
.g-invoiceHeader{
    display: flex;
    width: 80%;
    justify-content: space-between;
}
.g-invoiceHeader img{
    object-fit: contain;
    width: 160px;
}

.invoiceCustomerDetailsTable{
    width: 100%;
}
.invoiceCustomerDetailsTable th{
    text-align: left;
}
.invoiceCustomerDetailsTable td{
    text-align: left;
}



table {
 border-collapse: collapse;
}

table, th, td {
 padding: 5px;
 text-align: center;
 padding: 0px !important;
}

.invoiceAmountContainer{
    width: 80%;
}
/* .invoiceAmountContainer table tbody tr th{
    border: 1px solid black;
}
.invoiceAmountContainer table tbody tr td{
    border: 1px solid black;
}
.invoiceAmountContainer table tbody{
    border: 1px solid black;
} */
/* .invoiceAmountContainer table{
    border-top: 1px solid #09bdc5;
    border-bottom: 1px solid #09bdc5;
} */

.amountDataRow .data-12{
    width: 12%;
}
.amountDataRow .data-14{
    width: 16%;
}

.invoiceAmountTable{
    width: 100%;
    margin: 0px;
}

.totalAmountData{
    padding: 13px 0px !important;;
}

.note-text-p{
    margin: 0px;
}

.invoice-note-container{
    width: 80%;
    margin-bottom: 5px;
}

.invoice-bankInfo-container{
    width: 80%;
    margin: 0px;
}

.invoice-bankInfo-col-1 h5{
    margin: 0px;
}

.invoice-bankInfo-col-1{
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.globalWorkerUrl{
    color: black;
    text-decoration: none;
}

.invoice-bankInfo-container td {
    width: 70%;
}
.invoice-bankInfo-container th {
    width: 30%;
}

.invoice-bankInfo-table{
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 0px;
}

.invoiceCustomerDetails{
    width: 80%;
}

.invoice-bankInfo-table th{
    text-align: left;
}
.invoice-bankInfo-table td{
    text-align: left;
}

.address-footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0px 0px 10px 0px;
}

.address-footer p{
    margin: 0px;
}

.buttonMainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-div{
    width: 90%;
    display: flex;
    justify-content: center;
    }
.previewInvoiceButtons:hover{
    background-color: #5696c3;
    }
.previewInvoiceButtons{
    margin: 0px 10px;
    padding: 14px 30px;
    border-radius: 25px;
    color: white;
    border: none;
}

.clspan-4-th{
    border-bottom: 1px solid white !important;
    border-left: 1px solid white !important;
}

.goBackBtn, .editInvoiceBtn{
    background-color: #800000 !important;
    border: none;
}
.sendInvoiceBtn{
    background-color: #041212 !important;
    border: none;
}

.cust-email-data{
    text-wrap: nowrap;
}

@media (max-width: 425px){
    .previewInvoiceButtons{
        margin-bottom: 10px;
    }
}
@media (max-width: 700px){
    .generatedInvoiceContainer{
        width: 800px !important;
        
    }
    .invoiceBodyContainer{
        display: block;
    }
}

@media (max-width: 1039px){
    .data-col{
        justify-content: start;
        align-items: start;
    }
    .invoiceCustomerDetails-row{
        width: 100%;
    }
    .dataCol1{
        padding-right: 30px;
    }
    .invoice-bankInfo-col-1{
        align-items: start;
    }
}


@media (max-width: 798px){
    .buttonMainContainer{
        width: 800px !important;
    }
    .previewInvoiceButtons{
        text-wrap: nowrap;
    }
}

@media print {
    .buttonMainContainer{
        display: none;
        margin: 0px !important;
    }
    .invoiceBodyContainer{
        padding-top: 0px !important;
    }

}