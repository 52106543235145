.invoiceListRow{
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-invoice-amount{
    height: 70px;
}

.invoice-col{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}