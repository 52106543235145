.fl-invoice-dropdown{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.custom-field-box{
    padding: 0.575rem 0.75rem !important;
    font-size: 1.16rem !important;
    /* border: var(--bs-border-width) solid #8f9499; */
}

.form-lbl{
    display: flex;
}

.create-invoice-btn-div{
    width: 80%;
    padding: 0px 0px 0px 12px;
}
.create-invoice-btn{
    display: flex;
    width: 40%;
    justify-content: center;
    border: none;
    border-radius: 7px;
    background-color: #153243;
    color: white;
    padding: 8px 30px;
}
.create-invoice-btn:hover{
    background-color: #204f6b;
}
.invoice-btn-grp{
    border: none;
    border-radius: 7px;
    width: 100%;
    padding: 8px 30px;
    color: white;
}
#inv-first-btn{
    background-color: #153243;
    text-wrap: nowrap;
}
#inv-first-btn:hover{
    background-color: #204f6b;
}
#inv-second-btn{
    background-color: #153243;
}
#inv-second-btn:hover{
    background-color: #204f6b;
}
#inv-third-btn{
    background-color: #153243;
}
#inv-third-btn:hover{
    background-color: #204f6b;
}
#inv-fourth-btn{
    background-color: #D77A61;
}
#inv-fourth-btn:hover{
    background-color: #cb5838;
}
#inv-fifth-btn{
    background-color: #D77A61;
    text-wrap: nowrap;
}
#inv-fifth-btn:hover{
    background-color: #cb5838;
}

.modal-footer-form{
    padding-left: 0.5em !important;
}

.invoice-row-container{
    flex-wrap: nowrap;
    overflow-x: auto;
}
.bold-txt{
    font-weight: bold;
}
.last-btn-div{
    margin: 0px 13px;
}
.popup-blue-header{
    background-color: #95B8D1;
    color: white;
    display: flex;
    justify-content: center;
}
.inv-total-amount{
    color: #95B8D1;
    display: flex;
}
.inv-save-btn{
    width: 165px;
    background-color: #11b120;
    color: white;
    border: none;
}
.inv-save-btn:hover{
    background-color: #5bca66;
}
.inv-close-btn{
    background-color: red;
    border: none;
    color: white;
}
.inv-close-btn:hover{
    background-color: #d63535;
}
.inv-popup-body{
    display: flex;
    justify-content: center;
}
.disabled-field{
    background-color: #dddddd !important;
}
@media (max-width: 746px){
    .invoice-create-form{
        flex-direction: column;
    }
    .create-invoice-btn-div{
        display: flex;
        justify-content: center;
        padding: 0px !important;
        width: 100%;
    }
    .create-invoice-btn{
        width: 200px !important;
    }
    .fl-invoice-section-heading{
        display: flex;
        justify-content: center !important;
        margin: 0px !important;
    }
}

@media (min-width: 1998px){
    .invoice-create-form{
        width: 1590px !important;
    }
    .invoice-btn-grp-main{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .invoice-btn-grp-div{
        width: 1450px !important;
    }
    .create-invoice-btn{
        width: 370px
    }
}
