.form-control:disabled{
    background: none;
    opacity: 1;
}

.row-1{
    display: flex;
    width: 100%;
}
.row-2{
    display: flex;
    width: 100%;
}
.label{
    width: 100%;
    display: flex;
    
}
.form-label{
    font-weight: normal;
}
.form-div{
    margin: 20px;

}
.form-input{
    width: 78%;
    margin-bottom: 30px;
}
.form-heading{
    display: flex;
    margin-left: 30px;
    font-weight: bold;
}
.contact-person-heading{
    font-weight: bold;
}
@media (max-width: 768px) {
.row-1, .row-2 {
    flex-direction: column; /* Change to a vertical layout on smaller screens */
    align-items: center;
}

.col {
    width: 100%; /* Full width for each column on smaller screens */
    margin-bottom: 20px; /* Add some spacing between columns */
}
}