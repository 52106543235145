.main-container-fl{
    width: 100%;
    display: flex;
    margin-left: 10px;
}
.left-container-fl{
    width: 45%;
}
.right-container-fl{
    width: 55%
}
.right-container-fl img{
    object-fit: contain;
    max-width: 100%;
}
.user-prof-radio-container{
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
    margin-top: 10px;
}
.Privatpersons-container{
    margin-right: 29px;
    
}

.Privatpersons-heading{
    display: flex;
}


.företags-kund-heading{
    display: flex;
}
.pvt-input-field{
    max-width: 100%;
    width: 100%;
}
.user-profile-save-btn-div{
    display: flex;
    padding: 0px 46px 0px 26px;
    width: 220px;
}
.user-profile-save-btn{
    /* width: 50%; */
    background: #95B8D1;
    color: white;
    border: none;

}

@media (max-width: 746px){
    .right-container-fl{
        display: none;
    }
    .left-container-fl{
        width: 100%;
    }
}
