.recruit-div{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.recruit-heading{
    font-weight: bold;
    display: flex;
}
.rec-btn{
    width: 450px;
    background-color: #95B8D1;
    color: white;
    border: none;
}
.rec-btn:hover{
    background-color: #153243;
    color: white;
    border: none;
}
@media (max-width: 565px) {
    .rec-btn{
        width: 80% !important;
    }
    .rec-btn-div{
        display: flex;
        flex-direction: column;
    }
    
}