.client-list-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .client-list-row {
    width: 95%;
  }

  .client-data-col{
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .orgNr{
    text-wrap: nowrap;
  }


  @media (max-width: 747px) {
    .client-list-row {
      width: 655px;
      overflow: auto;
      display: flex;
      flex-wrap: nowrap;
    }
    .client-data-col-div{
      justify-content: start;
    }
  }