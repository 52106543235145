.reset-form{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.main-container-reset{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 746px){
    .reset-form{
        width: 100% !important;
    }
}