.copyWrite{
    padding-left: 100px;
    margin-top: 40px;
    margin-bottom: 20px;
}

@media (max-width: 746px) {
    .copyWrite{
        padding-left: 0px !important;
    }
    .footer-div{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0px 18px;
    }
}

@media print{
    .footer-div{
        display: none !important;
    }
    .copyWrite{
        margin: 0px !important;
        padding: 0px !important;
        display: none !important
    }
}