.client-address-heading{
    display: flex;
    font-weight: bold;
}
#vat-help{
    text-align: left;
}

@media (max-width: 746px) {
    .fl-create-client-heading{
        width: 80%;
    }
    
}
