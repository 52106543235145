.blue-box-design {
    width: 100%;
    height: 40px;
    background-color: #95B8D1;
    border-radius: 30px 30px 0px 0px;
    display: flex;
}
.backBtn img{
    width: 40px;
}
.backBtn{
    border-radius: 30px 0px 0px 0px;
    padding: 2px 30px;
    background: linear-gradient(291deg, #95B8D1, white);
    border: none;
}

.container-layout {
    background-color: white !important;
    padding: 0px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    max-width: 80%;
}
.heading-div {
    width: 81%;
    height: 92px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 28px;
}
.valuta-heading {
    background-color: #95B8D1;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    margin-right: 20px;
    border: none;
}

.invoice-search-form{
    display: flex;
    justify-content: center;
}
.invoice-labels{
    display: flex;
}

@media(max-width: 745px){
    .container-layout{
        max-width: 95% !important;
    }
    
}