.row {
    max-width: 100%;
    display: flex;
    
}
.table-rows{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}


.col {
    flex: 1;
    padding: 10px;
}
select {
    border: 1px solid #95B8D1;
    border-radius: 5px;
}
option {
    border: none !important;
}
.table-div{
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 90% !important;
}

/* Add this style to make the first column content bold */
.row .col:first-child {
    font-weight: bold;
}
.row-2 {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 95%;
    flex-wrap: wrap;
}

@media (max-width: 768px){
.row-1, .row-2 {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}
}