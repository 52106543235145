body{
    background-color: #F5F5F5;
    font-family: "Century Gothic";
}
.dashboard-btn{
    width: 278px;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 19px;
    background-color: white;
    color: #153243;
    border: none;
    box-shadow: 0px 0px 5px -1px #00000059;
    border-radius: 17px;
}
.btn-heading{
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.dashboard-btn:hover{
    background-color: rgb(221, 221, 221);

}
.dashboard-btn:active{
    background-color: #c0c0c0 !important;
}


.dashboard-btn img{
    object-fit: contain;
    
}
.dash-img{
    width: 100%;
    display: flex;
    justify-content: end;

}
.dash-img img{
    object-fit: contain;
    width: 517px;
    height: 365px;
}
#dashboard-heading{
    display: flex;
    color: #153243;
    font-weight: bold;
}
.dash-img-freelancer{
    width: 100%;
    display: flex;
    justify-content: end;

}

.dash-img-freelancer img{
    object-fit: contain;
    width: 517px;
    height: 365px;

}
@media (max-width: 745px){
    .dash-img{
        display: none;
    }
    .container{
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .col{
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .container-fluid{
        display: flex !important;
        justify-content: center !important;
    }
    .navbar-toggler{
        margin-right: 30px !important;
    }
}