.read-more-btn-div-bi{
    display: flex;
    padding-left: 0.5em;
}
.read-more-btn-bi{
    width: 107px;
    height: 33px;
    flex-shrink: 0;
    background-color: #e3a114;
    border-radius: 20px;
    color: white;
    border: none;
}
.alternative1-container{
    margin-bottom: 20px;
    text-align: left;
    display: flex;
}
.alternative2-container{
    text-align: left;
    display: flex;
}
.bank-info-secondary-txt{
    font-weight: bold;
    display: flex;
    margin-bottom: 10px;
}
.tax-info-secondary-txt{
    text-align: left;
}
.tax-info-secondary-txt2{
    margin-top: 50px;
    margin-bottom: 30px;
}
.bank-save-btn-div{
    width: 100%;
    display: flex;
    padding: 30px 50px;
}
.bank-save-btn{
    width: 121px;
    height: 41px;
    flex-shrink: 0;
    background-color: #95B8D1;
    border-radius: 10px;
    border: none;
}
.bank-save-btn:hover{
    background-color: #153243;
}
.bank-otherInfo-label{
    width: 45%;
}
.CertificateBtn{
    padding: 10px 20px;
    border-radius: 12px;
    background: linear-gradient(45deg, #80abc9, transparent);
    color: #231d1d;
    border: 1px solid #ddcece;
}

@media (max-width: 660px){
    .bank-otherInfo-label{
        width: 227px !important;
    }
}
@media (max-width: 1320px){
    .read-more-btn-div-bi{
        padding-left: 1.5rem !important;
    }

    .alternative1-container{
        flex-direction: column;
    }
    .alternative2-container{
        flex-direction: column;
    }
}
@media (max-width: 1066px){
    .bank-info-main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bank-info-right{
        margin-top: 50px;
        padding: 0px;
        width: 80% !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .bank-info-left{
        padding-left: 0px !important;
        width: 80% !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .bank-info-heading-div{
        justify-content: center !important;
        align-items: center;
        width: 95%;
        margin-left: 0px !important;
    }
    .bank-info-heading{
        margin-right: 0px !important;
    }
    .bank-save-btn-div{
        padding: 30px 90px !important;
    }
}

/* Freelancer Bank Account Css */
.fl-wise-account-form-container{
    display: flex;
    text-align: left;
    margin-top: 45px;
    flex-direction: column;
}
.fl-wise-account-form-heading{
    padding-right: 62px;
}
.wise-dropdown{
    color: black !important;
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white !important;
}

.wise-bank-name-div{
    width: 100% ;
}
.wise-bank-field{
    width: 45%

}
.red-sterik{
    color: red;
}

@media (max-width: 1066px){
    .wise-dropdown{
        width: 216px;   
    }
    .wise-bank-field{
        width: 216px !important;
    }
}

/* Tax Info Css */

.tax-heading-fl{
    font-weight: bold;
}